export const filterByKey =
  <TItem>(key: keyof TItem) =>
  (i1: TItem, i2: TItem) => {
    if (i1[key] < i2[key]) {
      return -1;
    }
    if (i1[key] > i2[key]) {
      return 1;
    }
    return 0;
  };
